"use client";
import { useQuery } from "@tanstack/react-query";
import { getIndexListing } from "../../utils/apiFunctions/listingFunction";
import ListingCard from "./ListingCard";
import { SkeletonLoaderCard } from "../lego/SkeletonLoader";
import ListingCardCarousel from "../lego/ListingCardCarousel";

interface ListingCardItem {
    feature_img: string;
    destination: string;
    title: string;
    price: number;
    base_currency: string;
    slug: string;
    price_text: string;
    id: number;
    iscustom_price: boolean;
    campaign: string;
    overview: string;
    tp_review_rating: number;
    tp_review_num: number;
    is_custom_operator: boolean;
    note: string;
}

function createListingGroup(item: ListingCardItem) {
    return (
        <ListingCard
            key={item.title}
            feature_img={item.feature_img}
            title={item.title}
            destination={item.destination}
            base_currency={item.base_currency}
            price={item.price}
            slug={item.slug}
            price_text={item.price_text}
            id={item.id}
            iscustom_price={item.iscustom_price}
            campaign={item.campaign}
            overview={item.overview}
            tp_review_rating={item.tp_review_rating}
            tp_review_num={item.tp_review_num}
            is_custom_operator={item.is_custom_operator}
            note={item.note}
        />
    );
}

export default function ListingCardGroup({ campaign }: { campaign: string }) {
    const { isFetching, isError, data } = useQuery({
        queryKey: ["indexListing", campaign],
        queryFn: ({ queryKey }) => getIndexListing(queryKey[1]),
    });

    if (isFetching) return <SkeletonLoaderCard />;
    if (isError) return null;

    return (
        <ListingCardCarousel
            listingResult={data}
            createFunction={createListingGroup}
        />
    );
}
